/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

let injectedScript = false;

export const onInitialClientRender = () => {
  //lägg body-variabeln
  if (process.env.APP_INSIGHTS && process.env.APP_INSIGHTS === "true") {
    function addJS(jsCode) {
      const s = document.createElement(`script`);
      s.type = `text/javascript`;
      s.innerText = jsCode;
      document.getElementsByTagName(`head`)[0].appendChild(s);
    }
    if (!injectedScript) {
      addJS(`
        var sdkInstance = "appInsightsSDK"; window[sdkInstance] = "appInsights"; var aiName = window[sdkInstance], aisdk = window[aiName] || function (e) { function n(e) { t[e] = function () { var n = arguments; t.queue.push(function () { t[e].apply(t, n) }) } } var t = { config: e }; t.initialize = !0; var i = document, a = window; setTimeout(function () { var n = i.createElement("script"); n.src = e.url || "https://az416426.vo.msecnd.net/scripts/b/ai.2.min.js", i.getElementsByTagName("script")[0].parentNode.appendChild(n) }); try { t.cookie = i.cookie } catch (e) { } t.queue = [], t.version = 2; for (var r = ["Event", "PageView", "Exception", "Trace", "DependencyData", "Metric", "PageViewPerformance"]; r.length;)n("track" + r.pop()); n("startTrackPage"), n("stopTrackPage"); var s = "Track" + r[0]; if (n("start" + s), n("stop" + s), n("addTelemetryInitializer"), n("setAuthenticatedUserContext"), n("clearAuthenticatedUserContext"), n("flush"), t.SeverityLevel = { Verbose: 0, Information: 1, Warning: 2, Error: 3, Critical: 4 }, !(!0 === e.disableExceptionTracking || e.extensionConfig && e.extensionConfig.ApplicationInsightsAnalytics && !0 === e.extensionConfig.ApplicationInsightsAnalytics.disableExceptionTracking)) { n("_" + (r = "onerror")); var o = a[r]; a[r] = function (e, n, i, a, s) { var c = o && o(e, n, i, a, s); return !0 !== c && t["_" + r]({ message: e, url: n, lineNumber: i, columnNumber: a, error: s }), c }, e.autoExceptionInstrumented = !0 } return t }(
            {
                instrumentationKey: "${process.env.APP_INSIGHTS_KEY}"
            }
        ); window[aiName] = aisdk, aisdk.queue && 0 === aisdk.queue.length && aisdk.trackPageView({});

        appInsights.addTelemetryInitializer(function (envelope) {
            const telemetryItem = envelope.baseData;
            telemetryItem.properties = telemetryItem.properties || {};
            telemetryItem.properties["urlReferrer"] = document.referrer;
            return true;
        });
        `);
      injectedScript = true;
    }
  }
};

// Currently "dekiru.se" has no routed pages, therefore this won't be triggered
// Not sure if it's needed in case we add pages in the future since there seems to be a .trackPageView() call in the JS snippet above
// Leaving it here just in case
export const onRouteUpdate = ({ location, prevLocation }) => {
  if (process.env.ANALYTICS && process.env.ANALYTICS === "true" && window.appInsights) {
    window.appInsights.trackPageView();
  }
};
